import React from 'react'
import Header from '../components/header'
import "../styles/404.scss"
import Seo from "../components/SEO/SEO"
import Boton from '../components/boton'




export default function NoEncontrado(props) {
    return (
        <div>
            <Header />
            <Seo title="¡Ups! Parece que te has perdido" pathname={props.location.pathname} />
            <div className="cuerpo-404 container">
                <div className="col col-izquierda">
                    <h1>Parece que te has perdido...</h1>
                    <p>Eso de que todos los caminos llevan a Roma está un poco anticuado, vuelve al inicio e inténtalo otra vez.</p>
                    <Boton color="secundario" url="/" texto="Volver a inicio" externo={false} />
                </div>
                <div className="col col-derecha">
                    <img src={'/img/toroBusqueda.svg'} alt="Caricatura Toro" />
                </div>
            </div>
        </div>
    )
}
